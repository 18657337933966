import React from 'react';

import './App.css';
import Home from './Pages/Home';
import Menu from './Components/Menu';
import Footer from './Components/Footer';
import Conteudo from './Pages/Conteudo';
import Mail from './Pages/Mail';

function App() {
  return (
    <div className="App">
      <Menu/>
      <Home/>
      <Conteudo/>
      <Mail/>
      <Footer/>
    </div>
  );
}

export default App;
