import React, { Component } from 'react';

import'./style.css';
import'./responsive.css';

import antispam from '../../Assets/img/antispam.webp'
import arquivamento from '../../Assets/img/arquivamento.webp'
import controle from '../../Assets/img/controle.webp'
import backup from '../../Assets/img/backup.webp'
import recibo from '../../Assets/img/recibo.webp'
import ScrollAnimation from 'react-animate-on-scroll';


export default class Mail extends Component {
  render() {
    return (<div className="mail_wrapper">
<ScrollAnimation animateIn="fadeIn" animateOnce>
<div className="title-default">
            <h1>FUNCIONAMENTO DO MAV MAIL</h1>
            {/* <p>It is a long established fact that a reader will be distracted by the of readable content Deploy.</p> */}
        </div>

        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b">
                <div className="conteudo_text_b">
                {/* <div className="text_session">Antispam</div> */}
                <div className="subtitle"><h2>MAV ANTISPAM</h2></div>
              Garanta 99,9% de eficácia na proteção contra spams, fraudes e phishing (roubo de informações) que chegam por e-mail diariamente. Para isso, são feitas análises de textos do e-mail, black list e assinaturas geradas automaticamente. O bloqueio desses spams além de garantir a segurança também aumenta a produtividade, evitando perda de tempo com e-mails que não são o foco do seu negócio.
                </div>
                <div className="img_conteudo imc">
                    <img src={antispam} alt="micro" widht="300px"/>
                </div>
            </div>
        </div>
        </ScrollAnimation>



        
        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b reverse">
                <div className="conteudo_text_b">
                {/* <div className="text_session">Arquivamento</div> */}
                <div className="subtitle"><h2>Arquivamento</h2></div>
                Acesse o registro de todo o tráfego e conteúdo dos seus e-mails. Além do melhor monitoramento dos dados, é possível recuperar mensagens já excluídas pelos usuários e fazer o arquivamento, ou seja, a documentação dessas informações.
                </div>
                <div className="img_conteudo imc">
                    <img src={arquivamento} alt="micro" widht="300px"/>
                </div>
            </div>
        </div>
        </ScrollAnimation>




        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b">
                <div className="conteudo_text_b">
                {/* <div className="text_session">Controle De Acesso</div> */}
                <div className="subtitle"><h2>Controle De Acesso</h2></div>
                Evite acessos inesperados que podem gerar horas extras de seus colaboradores ou aumentar o risco de vazamentos de informações confidenciais. Basta bloquear o acesso aos e-mails fora do horário comercial ou da rede da empresa.
                </div>
                <div className="img_conteudo imc">
                    <img src={controle} alt="micro" widht="300px"/>
                </div>
            </div>
        </div>
        </ScrollAnimation>





                
        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b reverse">
                <div className="conteudo_text_b">
                {/* <div className="text_session">Backup</div> */}
                <div className="subtitle"><h2>Backup</h2></div>
                Tenha cópias de segurança de todos os e-mails que circulam na empresa e recupere qualquer mensagem, de maneira imediata. Esses e-mails não podem ser alterados ou apagados e ficam armazenados até 10 anos.
                </div>
                <div className="img_conteudo imc">
                    <img src={backup} alt="micro" widht="300px"/>
                </div>
            </div>
        </div>

        </ScrollAnimation>


        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b">
                <div className="conteudo_text_b">
                {/* <div className="text_session">Recibo De Entrega</div> */}
                <div className="subtitle"><h2>Recibo De Entrega</h2></div>
                Identifique problemas no envio de e-mails e evite falhas na comunicação com seu cliente. Disponibilizamos relatórios para que você cheque se um e-mail saiu da sua caixa de saída ou parou no servidor do destinatário.
                </div>
                <div className="img_conteudo imc">
                    <img src={recibo} alt="micro" widht="300px"/>
                </div>
            </div>
        </div>

        <div className="button">CONVERSE AGORA COM NOSSA EQUIPE</div>
        </ScrollAnimation>
    </div>
    
    );
  }
}
