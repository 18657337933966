import React, { Component } from 'react';

import'./style.css';
import'./responsive.css';
import logo from '../../Assets/img/logo.svg'

import $ from 'jquery'

export default class Menu extends Component {

  componentDidMount(){
    $(document).ready(function($){
      $(window).scroll(function() {    
       if ($(window).scrollTop() >= 20) {
         $('.menu_wrapper').addClass('back');
       }else{
           $('.menu_wrapper').removeClass('back');
       }
   });
 });
  }
  render() {
    return <div className="menu_wrapper">
        <div className="logo">
            <img src={logo} alt="logomarca"/>
        </div>
        <div className="nav"></div>
        <div className="contato">

            {/* <div className="circle_icon"><i class="fas fa-phone"></i></div> */}
            <div className="tel">
                {/* <div className="text"></div> */}
                entre em contato
                </div>

        </div>
    </div>;
  }
}
