import React, { Component } from 'react';

import './style.css';

import './responsive.css';
// import micro from '../../Assets/img/micro.svg'
import im from '../../Assets/img/im.svg'
import imb from '../../Assets/img/imb.svg'
import imc from '../../Assets/img/imc.gif'
import ScrollAnimation from 'react-animate-on-scroll';

export default class Conteudo extends Component {
  render() {
    return (
    
    <div className="conteudo_wrapper">

<ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="title-default">
            <h1>FUNCIONAMENTO DO <b>AMBIENTE MISTO</b></h1>
            {/* <p>It is a long established fact that a reader will be distracted by the of readable content Deploy.</p> */}
        </div>

        <div className="headline_conteudo">
        A MAV Tecnologia implementou em sua infraestrutura um modelo de funcionamento que se integra diretamente com a Microsoft, este modelo
                    permitirá que a sua empresa <b>diminua de forma considerável</b> o custo com os serviços de e-mails da Microsoft e continue mantendo o alto nível dos serviços
                    ao criar um ambiente misto com o MAV Mail.
        </div>
</ScrollAnimation>
        {/* <div className="subtitle"><h2>O ambiente misto funcionará assim:</h2></div> */}
        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo reverse">
                <div className="conteudo_text">
                    <div className="text_session">o ambiente</div>
                <div className="subtitle"><h2>EXEMPLO DE <b>IMPLEMENTAÇÃO DO AMBIENTE</b></h2></div>
                Você deverá escolher quais contas de e-mails deverão permanecer utilizando os serviços da Microsoft, possivelmente estas são as contas mais importantes do seu domínio e que necessitam da utilização de recursos do Exchange. Normalmente estas são as contas de: CEOs, Diretores, Presidentes, Sócios, Gerentes e afins.
                <div className="sub_conteudo">
                As demais contas de e-mail do seu domínio, realizaremos a hospedagem na MAV Tecnologia, logo abaixo você poderá ver os principais recursos dos nossos
                serviços de e-mail premium.
                </div> 
                </div>
          

                <div className="img_conteudo">
                    <img src={imb} alt="micro"/>
                </div>
            </div>
        </div>
        </ScrollAnimation>
        {/* <div className="subtitle"><h2>VEJA COMO A ECONIMA DE 70% ACONTECE</h2></div> */}
        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b">
                <div className="conteudo_text_b">
                <div className="text_session">economia</div>
                <div className="subtitle"><h2>BAIXO CUSTO E ALTA <b>QUALIDADE</b></h2></div>
                Suponhamos que atualmente a sua empresa possui 100 contas de e-mails hospedadas na Microsoft, isto seria um <b>custo em torno de R$1890,00/mês.</b>
                </div>
                <div className="img_conteudo imc">
                    <img src={imc} alt="micro" widht="300px"/>
                </div>
            </div>
        </div>

        </ScrollAnimation>
        {/* <div className="subtitle"><h2>COM O AMBIENTE MISTO</h2></div> */}

        <ScrollAnimation animateIn="fadeIn" animateOnce>
        <div className="conteudo_box_wrapper">
            <div className="boxes_conteudo_b reverse">
                <div className="conteudo_text_b">
                <div className="text_session">economia</div>
                <div className="subtitle"><h2>INVESTIMENTO COM O <b>AMBIENTE MISTO</b></h2></div>
                Agora vamos utilizar o ambiente misto para a sua empresa, com isto, você identifica que das 100 contas de e-mails, 10 precisam utilizar os recursos do Microsoft Exchange e as 90 caixas postais restantes, serão hospedadas na MAV Tecnologia, o cenário ficaria assim:
                
                <ul>
                    <li>10 caixas postais serão hospedadas na Microsoft: R$189,00</li>
                    <li>90 caixas postais serão hospedadas na MAV Tecnologia: R$360,00</li>
                    <li>Total utilizando o ambiente misto: <b>R$549,00</b></li>
                    <li>Economia de <b>R$1.341/mês,</b> o que é aproximadamente 70% de custo a menos.</li>
                    <li>A mensalidade dos serviços de e-mails da sua empresa passará de R$1890/mês para apenas R$549/mês e a qualidade se manterá a mesma!</li>
                </ul>
                </div>
                <div className="img_conteudo im">
                    <img src={im} alt="micro"/>
                </div>
            </div>
        </div>
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce>
        {/* <div className="link_area">
        VEJA OS RECURSOS DOS SERVIÇOS DE E-MAIL DA MAV TECNOLOGIA <a href="https://produtos.mav.com.br/mail"> CLIQUE AQUI</a> 
        </div> */}

        <div className="button">CLIQUE AQUI AGORA E CONVERSE COM NOSSO CONSULTOR</div>

        </ScrollAnimation>
        
    </div>

    )
  }
}
